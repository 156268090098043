#ib_level {
  .ib_level_header {
    margin-bottom: 40px;

    .select {
      width: 308px;
      display: inline-block;
      margin-right: 45px;

      /deep/ .el-form-item {
        margin-bottom: 32px;
      }
    }
  }

  .ib_level_content {
    .title {
      font-size: 24px;
      font-weight: bold;
      line-height: 1.42;
      color: $primary-purple;
      margin-bottom: 40px;
    }
  }

  /deep/ .ib_level_content--upper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;

    .ib-level-card {
      width: 360px;
      height: 150px;
      padding: 26px;
      border: $container-border;
      border-radius: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .el-card__body {
        display: flex;
      }

      .ib-level-image-wrapper {
        display: flex;
        justify-content: center;
        text-align: center;

        .ib-image--upper {
          height: 65px;
          display: block;
        }
      }

      .ib_level_content-subtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 1.83;
        color: $primary-purple;
        word-wrap: break-word;
        padding: 0 20px;
      }

      .ib_level_content-title {
        font-size: 14px;
        font-weight: 200;
        color: $primary-purple;
        padding: 0 20px;
      }

      &.ib-card__active {
        background: $primary-green !important;
        border: none;

        .ib_level_content-subtitle,
        .ib_level_content-title {
          color: #ffffff;
        }
      }
    }
  }

  .ib_level--alert {
    p {
      font-size: 14px;
      font-weight: 200;
      color: $primary-purple;
      padding: 5px;
      text-align: center;
      margin: 30px 0;
    }
  }
}

@media (max-width: 550px) {
  #ib_level {
    .ib_level_header .select {
      width: 100%;
      @include rtl-sass-prop-sd(margin-right, 0px);
    }

    /deep/ .ib_level_content--upper .ib-level-card {
      width: 100%;
      height: unset;
      display: inline-block;
      padding: 23px 24px 14.5px;

      .ib-level-image-wrapper {
        justify-content: center;
        text-align: center;
        display: inline-block;
        @include rtl-sass-value(float, left, right);
      }

      .info {
        display: inline-block;
        @include rtl-sass-value(float, right, left);
      }
    }

    .ib_level_content .sub_ib {
      .title {
        width: 100%;
        padding: 25px 23px;
        background-color: #ffffff;
        box-shadow: $box-shadow;
        margin-bottom: 32px;
        position: relative;

        img {
          position: absolute;
          bottom: 34px;
          @include rtl-sass-prop(right, left, 20px);
          background: url(~@/assets/uploads/icons-navigation-cheveron-down@3x.png);
          @include rtl-sass-value(padding, 16px 16px 0 0, 16px 0 0 16px);
          background-size: 16px 16px;
          background-repeat: no-repeat;
        }

        &.openTitle img {
          background: url(~@/assets/uploads/icon_caret_bottom.png);
          @include rtl-sass-value(padding, 16px 16px 0 0, 16px 0 0 16px);
          background-size: 16px 16px;
          background-repeat: no-repeat;
        }
      }

      .level-card-wrapper {
        display: none;
      }
    }
  }
}
